@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600&family=Poppins:wght@200;300;400;500;600;700&display=swap');

/*
font-family: 'Inter', sans-serif;
font-family: 'Poppins', sans-serif;
*/

@media (min-width: 992px){
.navbar-expand-lg .navbar-collapse {position: absolute;right: 3rem;}
}


.modal-booking-body{max-height: 90%; overflow-y: scroll; overflow-x: hidden; flex-wrap: nowrap;}
.modal-booking-body .book-service{width: 100%; height: auto; overflow: hidden; border: 1px solid #CFD6E5; border-radius: 6px; padding: 1rem; margin-bottom: 12px; cursor: pointer;}
.modal-booking-body .book-service:hover{border: 1px solid #03B38C; transition: ease .6s;}
.modal-booking-body .book-service-img{width: 90px; float: left; text-align: center;}
.modal-booking-body .book-service-content{width: calc(100% - 90px); float: left; padding-top: 2px;}
.modal-booking-body .book-service-content h5{color: #3e3b3b; font-family: 'Poppins', sans-serif; font-size: 1.2rem; font-weight: 500; overflow: hidden; -webkit-box-orient: vertical; -webkit-line-clamp: 2; display: -webkit-box; min-height: 3.6rem; line-height: 1.8rem;}

.modal-booking-body .book-doctor{width: 100%; height: auto; overflow: hidden; border: 1px solid #CFD6E5; border-radius: 6px; padding: 1rem; margin-bottom: 12px; cursor: pointer;}
.modal-booking-body .book-doctor:hover{border: 1px solid #03B38C; transition: ease .6s;}
.modal-booking-body .book-doctor-img{width: 100%; float: left; text-align: center; display: block;}
.modal-booking-body .book-doctor-img img{border-radius: 10px; width: 100px;}
.modal-booking-body .book-doctor-content{width: 100%; float: left; padding-top: 1rem; text-align: center;}
.modal-booking-body .book-doctor-content h5{color: #3e3b3b; font-family: 'Poppins', sans-serif; font-size: 1.2rem; font-weight: 500; margin-bottom: 0px;}
.modal-booking-body .book-doctor-content p{text-align: center;}

.modal-booking-body .book-date{height: 100%; overflow: hidden; border: 1px solid #CFD6E5; border-radius: 6px; margin-bottom: 8px; padding: 1rem 2rem; max-height: 60vh; overflow-y: scroll;}
.modal-booking-body .book-date h5{color: #3e3b3b; width: 100%; display: block; font-family: 'Poppins', sans-serif; font-size: 1.2rem; font-weight: 500; margin-bottom: 1rem;}
.modal-booking-body .book-date h6{color: #3e3b3b; width: 100%; display: block; font-family: 'Poppins', sans-serif; font-size: 1rem; font-weight: 400; margin: 1rem 0;}
.modal-booking-body .book-date-tile{border: 1px solid #CFD6E5; margin-bottom: 6px; height: 50px; text-align: center; padding: .8rem 0 !important; cursor: pointer;}
.modal-booking-body .book-date-tile:hover{border: 1px solid #03B38C; transition: ease .6s;}


.book-doctor-summary{text-align: center; padding: 3rem 0;}
.book-doctor-summary h5{color: #3e3b3b; width: 100%; display: block; font-family: 'Poppins', sans-serif; font-size: 1.8rem; font-weight: 500; margin: 1rem 0;}

.modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
  }
  
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  .main_canvas_search {
    position: relative;
  }
  
  .search-results {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000; /* Adjust the z-index as needed */
  }
  
  .search-results > div {
    padding: 10px;
    border-bottom: 1px solid #eee;
  }
  
  /* Add more styles as needed */
  
  .time-slots {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .time-slot {
    padding: 10px;
    margin: 5px;
    border: 1px solid #ccc;
    cursor: pointer;
  }
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  
  .open {
    display: block;
  }
  
  .closed {
    display: none;
  }
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
  }

.navbar{}
.home-navbar{}
.home-navbar .nav-link{padding-right: 2rem !important; color: #000000; font-family: 'Rubik', sans-serif; font-size: 1rem; font-style: normal; font-weight: 400; line-height: normal;}

.main_canvas{background-color: #ffffff; background-position: center; background-size: cover; background-repeat: no-repeat;}
.main_canvas_holder{z-index: 2; position: relative; padding: 14% 0 15%;}
.main_canvas_holder h1{color: #000000; font-family: 'Poppins', sans-serif; font-size: 2.8rem; font-style: normal; font-weight: 700; line-height: 1.2;}
.main_canvas_holder p{color: #000000; font-family: 'Inter', sans-serif; font-size: 1.2rem; font-style: normal; font-weight: 600 !important; line-height: normal;}
.main_canvas_holder p span{color: #f07404; display: block; font-weight: 500 !important; font-size: 1rem; padding-top: 10px;}

.main_canvas_search{box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.25);; border-radius: 6px; width: 80%; height: auto; padding: 2rem; display: flex; margin: 0 10%; margin-top: -50px; background-color: #ffffff; z-index: 3;}
.main_canvas_search input{width: 100%; float: left; border: none; z-index: 99;}
.main_canvas_search input:focus{box-shadow: none; border: none; border-bottom: 2px; border-color: #86b7fe;}
.main_canvas_search a{width: 100%; float: left; margin-left: 2%; --bs-btn-padding-y:.7rem; z-index: 99;}

.home-feature{width: 100%; height: 100px; border-radius: 5px; border: 1px solid #e5e9ec; background-color: #ffffff; display: block; padding: 30px 0; text-align: center; font-family: 'Poppins', sans-serif; font-size: 1.1rem; font-weight: 500; color: #3e3b3b; text-decoration: none; margin: 2rem 0; transition: ease 1s;}
.home-feature img{margin-right: 1rem;}
.home-feature:hover {box-shadow: 0px 4.5px 20px 0px rgba(0, 0, 0, 0.25); transition: ease 1s;}

@media (max-width: 991.98px){
.home-navbar .nav-link{font-size: 1.2rem; font-style: normal; font-weight: 500; line-height: 2;}

.main_canvas{height: auto;}
.main_canvas_holder h1{font-size: 3.14rem; line-height: 3.48rem; color: #ffffff;}
.main_canvas_holder p{font-size: 1.2rem; font-weight: 500; color: #ffffff;}
.main_canvas-banner{display: none;}
.main_canvas_cover{background: rgb(0, 0, 0, .3);}
.main_canvas_holder{padding-top: 8rem;}
.main_canvas_holder .btn-special-cta{width: 100%;}

.main_canvas_search input{width: 100%;}
.main_canvas_search a{width: 100%; display: block; margin-top: 1rem;}

.home-feature{margin: 1rem 0;}
}

.btn-special-cta{border-radius: 100px; box-shadow: 0px 7px 6px 0px rgba(96, 75, 219, 0.1); padding: .5rem 3rem !important;}

.bg1{height: auto; overflow: hidden; padding: 4rem 0;}
.bg2{height: auto; overflow: hidden; padding-top: 4rem;}

.a1{color: #3e3b3b; font-family: 'Poppins', sans-serif; font-size: 3.2rem; font-style: normal; font-weight: 600; padding-bottom: 2rem;}
.a1 span{font-size: .8rem; color: #EF7F1A; font-weight: 600; display: block;}
.a2{color: #515a61; font-family: 'Inter', sans-serif; font-size: 1.1rem; font-style: normal; font-weight: 400;}
.a3{color: #515a61; font-family: 'Inter', sans-serif; font-size: .8rem; font-style: normal; font-weight: 400; margin-bottom: 3rem;}
.a4{color: #3e3b3b; font-family: 'Inter', sans-serif; font-size: 1.1rem; font-style: normal; font-weight: 500;}
.a5{color: #3e3b3b; font-family: 'Inter', sans-serif; font-size: 1.4rem; font-style: normal; font-weight: 600;}
.a5 span{display:block; font-size: 1rem; font-weight: 600; margin-top: 1rem;}
.a6{color: #3e3b3b; font-family: 'Poppins', sans-serif; font-size: 2rem; font-style: normal; font-weight: 600; padding-bottom: 2rem;}


.featured_pointers1{color: #3e3b3b; font-family: 'Inter', sans-serif; font-size: 1rem; font-style: normal; font-weight: 500; padding-bottom: 1rem;}
.featured_pointers1 span{width: 20px; height: 20px; border-radius: 10px; background-color: #EF7F1A; float: left; margin-right: 1rem; content: '';}

.home_speciality_tab_image{border-radius: 8px;  width: 100%;}
.home_speciality_tab{height: auto; overflow: hidden; padding:0 0 2.5rem 0;}
.speciality_tab1{width: 100%; height: 140px; margin-bottom: 1.2rem; background-color: #ffffff; border-radius: 5px; border: 1px solid #e5e9ec; transition: ease 1s; text-align: center; text-decoration: none; display: block; cursor: pointer;}
.speciality_tab1:hover{background-color: #dfe7f0; box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25); transition: ease .6s;}
.speciality_tab1 img{margin: 1.6rem 0 .8rem 0;}
.speciality_tab1 span{display: block; color: #3e3b3b; text-decoration: none; font-family: 'Poppins', sans-serif; font-size: 1rem; font-weight: 500;}

.speciality_tab2{width: 100%; height: 30rem; margin-bottom: 1.2rem; background-color: #ffffff; border-radius: 10px; border: 1px solid #e5e9ec; transition: ease 1s; text-align: center; text-decoration: none; display: block; background-size: cover; background-position: center; background-repeat: no-repeat; font-family: 'Poppins', sans-serif; font-size: 2rem; color: #ffffff; font-weight: 500; cursor: pointer;}
.speciality_tab2:hover{background-color: #dfe7f0; box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.5); transition: ease 1s;}
.speciality_tab2 span{width: 100%; height: 30rem; padding: 23rem 2rem 0 2rem; border: none; background: rgb(0,0,0); background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.6) 100%); border-radius: 10px; display: block; text-align: left;}

.speciality_tab3{width: 100%; height: auto; overflow: hidden; margin-bottom: 1.2rem; padding: 2rem; background-color: #ffffff; border-radius: 10px; border: 1px solid #e5e9ec; transition: ease .5s; text-align: left; text-decoration: none; display: block; font-family: 'Poppins', sans-serif; font-size: 2.2rem; color: #515a61; font-weight: 500;}
.speciality_tab3:hover{background-color: #EF7F1A; color: #ffffff; box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.5); transition: ease .5s;}
.speciality_tab3 .image{background-color: #EF7F1A; width: 70px; height: 70px; border-radius: 40px; content: ''; text-align: center; display: block;}
.speciality_tab3 .image img{-webkit-filter: invert(100%); filter: invert(100%); margin: 15px 10px;}
.speciality_tab3:hover .image{background-color: #ffffff;}
.speciality_tab3:hover .image img{filter: invert(51%) sepia(54%) saturate(1818%) hue-rotate(354deg) brightness(101%) contrast(87%);}
.speciality_tab3 .title{width: 100%; padding: 1rem 0; text-align: left;}
.speciality_tab3 .title a{font-family: 'Poppins', sans-serif; color: #EF7F1A; font-size: 1.4rem; font-weight: 700; padding-top: .6rem; text-decoration: none;}
.speciality_tab3:hover .title a{color: #ffffff; transition: ease .5s;}
.speciality_tab3 p{font-family: 'Inter', sans-serif; font-size: 1.1rem; font-weight: 400; text-decoration: none;  min-height: 8rem; overflow: hidden; -webkit-box-orient: vertical; -webkit-line-clamp: 4; display: -webkit-box;}
.speciality_tab3 button{color: #EF7F1A; font-family: 'Inter', sans-serif; font-size: 1.1rem; font-weight: 600; text-decoration: none; background:none; border: none;}
.speciality_tab3:hover button{color: #ffffff;}
.speciality_tab3:hover p{color: #ffffff;}

.speciality_tab4{width: 100%; height: 100%; overflow: hidden; margin-bottom: 1.2rem; padding: 2rem 2rem 0 2rem; background-color: #ffffff; border-radius: 10px; border: 1px solid #e5e9ec; transition: ease .5s; text-align: left; text-decoration: none; display: block; font-family: 'Poppins', sans-serif; font-size: 1.8rem; color: #515a61; font-weight: 500;}
.speciality_tab4:hover{background-color: #EF7F1A; color: #ffffff; box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.5); transition: ease .5s;}
.speciality_tab4 .image{background-color: #EF7F1A; width: 70px; height: 70px; border-radius: 40px; content: ''; text-align: center; display: block;}
.speciality_tab4 .image img{-webkit-filter: invert(100%); filter: invert(100%); margin: 15px 10px;}
.speciality_tab4:hover .image{background-color: #ffffff;}
.speciality_tab4:hover .image img{filter: invert(51%) sepia(54%) saturate(1818%) hue-rotate(354deg) brightness(101%) contrast(87%);}
.speciality_tab4 .title{width: 100%; padding: .5rem 0; text-align: left;}
.speciality_tab4 p{font-family: 'Inter', sans-serif; font-size: 1.1rem; font-weight: 400; text-decoration: none;}
.speciality_tab4 a{color: #EF7F1A; font-family: 'Inter', sans-serif; font-size: 1.1rem; font-weight: 600; text-decoration: none;}
.speciality_tab4:hover a{color: #ffffff;}
.speciality_tab4:hover p{color: #ffffff;}

.home_our_doctors{background-color: #fafbfd; height: auto; overflow: hidden; padding: 4rem 0; margin: 5rem 0;}
.home_our_doctors_container{background-color: #ffffff; border-radius: 10px; box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25); padding: 2rem; margin: 4rem 0;}
.home_our_doctors_container p{color: #515a61; font-family: 'Inter', sans-serif; font-size: .8rem; font-style: normal; font-weight: 500; margin-bottom: 3rem; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 4; overflow: hidden;}
.home_doctor_detail{ text-align: center; }
.home_doctor_detail img{border-radius: 8px;}
.home_doctor_detail_content{text-align: left;}
.home_doctor_detail_content .doctor-name{font-family: 'Poppins', sans-serif; color: #3e3b3b; font-size: 1.2rem; font-weight: 500; padding-top: 1rem; text-decoration: none;}
.home_doctor_detail_content .doctor-name:hover{color: #f07404; transition: ease .5s;}
.home_doctor_detail h5{font-family: 'Poppins', sans-serif; color: #3e3b3b; font-size: 1.2rem; font-weight: 500; padding-top: .6rem;}
.home_doctor_detail p{font-family: 'Inter', sans-serif; font-size: 1rem; color: #515a61; font-weight: 500; margin:1px 0 5px 0; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 1; overflow: hidden;}
.home_doctor_detail p span{display: block; font-size: .8rem; color: #848c93; font-weight: 400; min-height: 3rem; overflow: hidden; -webkit-box-orient: vertical; -webkit-line-clamp: 2; display: -webkit-box;}

@media (max-width: 991.98px){
.home_doctor_detail h5{font-size: 1.6rem;}
.home_doctor_detail p{}
.home_doctor_detail p span{font-size: 1rem;}
.home_our_doctors_container p{font-size: 1rem;}
.speciality_doctor_detail p{}
.home_doctor_detail_content .doctor-name{font-size: 1.4rem; line-height: 2;}
}

.home_feature_tab1{width: 100%; height: 140px; margin-bottom: 1.2rem; background-color: #ecf1f5; padding: 1.6rem 2rem 0 2rem; border-radius: 15px; border: 1px solid #e5e9ec; box-shadow: 0px 2px 10px 0px rgba(234, 234, 234, 0.25); text-align: center; text-decoration: none; display: block; font-family: 'Poppins', sans-serif; color: #011f44; font-size: 2rem; font-weight: 700;}
.home_feature_tab1 img{margin: 0 0 .8rem 0;}
.home_feature_tab1 span{display: block; color: #3e3b3b; text-decoration: none; font-family: 'Inter', sans-serif; font-size: 1rem; font-weight: 500;}

.home_blog{border-radius: 8px; height: auto; overflow: hidden; border: 1px solid #e5e9ec;}
.home_blog_image{height: 300px; width: 100%; background-size: cover; background-repeat: no-repeat; background-position: center; content: '';}
.home_blog_content{padding: 1rem; height: auto; overflow: hidden;}
.home_blog_content_specs{width: 100%; height: auto; overflow: hidden;}
.home_blog_content_specs .specs_date{width: 50%; float: left; font-family: 'Inter', sans-serif; font-size: .8rem; color: #515a61; text-align: left; font-weight: 400;}
.home_blog_content_specs .specs_cat{width: 50%; float: left; font-family: 'Inter', sans-serif; font-size: .8rem; color: #f07404; text-align: right; font-weight: 600;}
.home_blog_content h3{font-family: 'Poppins', sans-serif; color: #515a61; font-size: 1.2rem; font-weight: 500; margin: 1rem 0; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; overflow: hidden;}
.home_blog_content p{font-family: 'Inter', sans-serif; color: #515a61; font-size: 1rem; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 4; overflow: hidden;}
.home_blog_content a{text-decoration: none; color: #515a61; font-family: 'Inter', sans-serif; font-size: .8rem; display: block; cursor: pointer;}
.home_blog_content:hover a{color:#f07404;}

@media (max-width: 991.98px){
.bg1{padding: 3rem 0;}
.home_speciality_tab_image{margin-bottom: 1rem;}

.home_our_doctors_container{margin-bottom: 2rem;}

.home_blog{margin-bottom: 2rem;}
}

.blogs_card{background: #fff; margin-top: 3rem; border-radius: 4px; width: 100%; height: auto; overflow: hidden; max-height: 720px; -webkit-box-shadow: 10px 20px 40px rgba(0, 0, 0, 0.05); box-shadow: 10px 20px 40px rgba(0, 0, 0, 0.05); border: 1px solid #dee2e6;}
.blogs_card .blog_banner{background-size: cover !important; background-repeat: no-repeat !important; background-position: center !important; width: 100%; height: 300px; content: '';}
.blogs_card .blog_intro_container{padding: 25px 20px; height: auto; overflow: hidden;}
.blogs_card .blog_intro_title{font-family: 'Almarai', sans-serif; font-size: 1.4rem; font-weight: 700; margin-bottom: 10px; color: #1C1C1C; display: block; display: -webkit-box; line-height: 1.2; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis;}
.blogs_card .blog_intro_content{font-family: 'Poppins', sans-serif; font-size: 14px; font-weight: 400; color: #1C1C1C;  display: block; display: -webkit-box; line-height: 1.2; -webkit-line-clamp: 3; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis;}
.blogs_card .blog_intro_container button{color:#EF7F1A; text-decoration: none; background:none; border: none;}

.blogs_card_main{background: #fff; width: 100%; height: auto; overflow: hidden; }
.blogs_card_main .blog_intro_container{padding: 25px 20px; height: auto; overflow: hidden;}
.blogs_card_main .blog_intro_title{font-family: 'Almarai', sans-serif; font-size: 2rem; font-weight: 500; margin-bottom: 10px; color: #1C1C1C;}
.blogs_card_main .blog_intro_title small{color: #EF7F1A; font-size: .8rem; display: block; font-weight: 700;}
.blogs_card_main .blog_intro_content{font-family: 'Poppins', sans-serif; font-size: 14px; font-weight: 400; color: #1C1C1C; margin-top: 45px;}
.footer{background-color: #eef5ff; margin-top: 3rem; padding: 4rem 0 2rem 0;}
.footer hr{margin: 3rem 0;}
.footer-logo{max-height: 60px; max-width: 100%; display: block; margin-bottom: 2rem;}

.footer-contact{list-style:none; margin: 2rem 0; padding: 0px; height: auto; overflow: hidden;}
.footer-contact li{width: 100%; display: block; margin-bottom: 1rem; color: #515a61; font-family: 'Inter', sans-serif; font-size: .9rem; font-weight: 400;}
.footer-contact li span{width: 40px; float: left;}
.footer-contact li a{display: block; color: #515a61; font-family: 'Inter', sans-serif; font-size: .9rem; font-style: normal; font-weight: 400; line-height: normal; padding-bottom:1rem; text-decoration: none;}
.footer-contact li a:hover{color: #EF7F1A; transition: ease 1s;}

.site-list1{height: auto; overflow: hidden; text-align: left;}
.site-list1 a{display: block; color: #515a61; font-family: 'Inter', sans-serif; font-size: 1rem; font-style: normal; font-weight: 600; line-height: normal; padding-bottom:1.38rem; text-decoration: none;}
.site-list1 a:hover{color: #EF7F1A; transition: ease .8s;}

.site-list2{height: auto; overflow: hidden; text-align: left;}
.site-list2 a{display: block; color: #515a61; font-family: 'Inter', sans-serif; font-size: 1rem; font-style: normal; font-weight: 400; line-height: normal; padding-bottom:1rem; text-decoration: none;}
.site-list2 a:hover{color: #EF7F1A; transition: ease 1s;}
.site-list2 h6{display: block; color: #515a61; font-family: 'Inter', sans-serif; font-size: 1.4rem; font-style: normal; font-weight: 600; line-height: normal; padding-bottom:1.4rem; text-decoration: none;}

.position_holder1{padding: 16rem 0 8rem 0;}
.position_holder1 h2{color: #614DD6; font-family: 'Rubik', sans-serif; font-size: 3.5rem; font-style: normal; font-weight: 500; line-height: 64px; padding-right: 30%;}
.position_holder1 h4{color: #614DD6; font-family: 'Rubik', sans-serif; font-size: 2.8rem; font-style: normal; font-weight: 500; line-height: 120%; padding-bottom: 2rem;}
.position_holder1 p{color: #614DD6; font-family: 'Rubik', sans-serif; font-size: 1.43rem; font-style: normal; font-weight: 300; line-height: 120%; padding-top: 12px; padding-bottom: 2rem;}

.btn-special-cta{border-radius: 100px; box-shadow: 0px 7px 6px 0px rgba(96, 75, 219, 0.1); padding: .5rem 3rem !important;}

.page-bg-title{background-repeat: no-repeat; background-position: center; background-size: cover; width: 100; padding: 3rem 0; margin-bottom: 1rem;}
.page-bg-title h1{color: #3e3b3b; font-family: 'Poppins', sans-serif; font-size: 3.2rem; font-style: normal; font-weight: 500; padding-top: 4rem;}

.breadcrumb{margin-top: 2rem; font-size: 1rem;}
.breadcrumb-item a{color:#EF7F1A; text-decoration: none;}

.speciality_our_doctors{background-color: #fafbfd; height: auto; overflow: hidden; padding: 4rem 0; margin: 5rem 0;}
.speciality_our_doctors_container{background-color: #ffffff; border-radius: 10px; box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25); padding: 1.4rem 1.6rem; margin: 4rem 0;}
.speciality_doctor_detail{text-align: center; padding-bottom: 1rem;}
.speciality_doctor_detail img{border-radius: 10px;}
.speciality_doctor_detail_content{text-align: left;}
.speciality_doctor_detail h5{font-family: 'Poppins', sans-serif; color: #3e3b3b; font-size: 1.1rem; font-weight: 500; padding-top: .6rem;}
.speciality_doctor_detail .doctor-name{font-family: 'Poppins', sans-serif; color: #3e3b3b; font-size: 1.1rem; font-weight: 500; padding-top: .6rem; text-decoration: none;}
.speciality_doctor_detail .doctor-name:hover{color: #f07404; transition: ease .5s;}
.speciality_doctor_detail p{font-family: 'Inter', sans-serif; font-size: 1rem; color: #515a61; margin: 1px 0 5px 0; overflow: hidden; -webkit-box-orient: vertical; -webkit-line-clamp: 1; display: -webkit-box;}
.speciality_doctor_detail p span{display: block; font-size: .8rem; color: #848c93; min-height: 3rem; overflow: hidden; -webkit-box-orient: vertical; -webkit-line-clamp: 2; display: -webkit-box;}


.pd1{padding-right: 30%;}
.pd2{padding-bottom: 3rem;}
.pd3{padding: 3rem 3rem 0 3rem;}
.pd4{padding-top: .4rem;}
.pd5{padding-bottom: 9rem;}
.pd6{padding: 3rem 0;}
.pd7{padding-top: 2rem;}
.pd8{padding-bottom: 3rem;}

@media (max-width: 991.98px){
.bg1{padding: 4rem 0 0 0;}
.bg2{background-position: inherit;}
.position_holder1 h2{padding-bottom: 4rem;}
.position_holder1 h4{font-size: 2rem; padding-bottom: 1rem;}
.position_holder1 p{font-size: 1.18rem; padding-bottom: 3rem;}

.bg_gradient{height: auto !important;}
}

.ReactModal__Content{z-index: 5; width: 80%; margin:3% 10%; inset:0px !important;}
.ReactModal__Overlay--after-open{background-color:rgb(0,0,0,.5) !important; z-index: 4;}
.react-calendar {width: 100%;}
.modal-footer{margin-top: 2rem;}
.modal-booking-body{margin: 3rem 4%;}

@media (max-width: 991.98px){
.ReactModal__Content{z-index: 5; width: 90%; margin:3% 5%;}
}

.job-detail{height: auto; overflow: hidden; margin:1rem 0;}
.job-detail h3{color: #3e3b3b; width: 100%; display: block; font-family: 'Poppins', sans-serif; font-size: 1.6rem; font-weight: 600; margin: 1rem 0;}
.job-detail p{font-family: 'Inter', sans-serif; color: #515a61; font-size: 1rem; font-weight: 400; padding-bottom: .6rem;}

.job-form{background-color: #eef5ff; margin-top: 3rem; padding: 1.6rem 2rem; border-radius: 1.2rem;}
.job-form h2{color: #3e3b3b; width: 100%; display: block; font-family: 'Poppins', sans-serif; font-size: 1.8rem; font-weight: 600; margin: 1rem 0;}
p {margin-top: 5px; line-height: 1.8; color: #555;}

.appointment-brief{background-color: #eaf9f8; padding: 1rem 1.2rem; border-radius: 8px; margin-bottom: 1rem; height: auto; overflow: hidden;}
.appointment-brief .doctor-name{font-family: 'Poppins', sans-serif; color: #3e3b3b; font-size: 1.2rem; font-weight: 700; text-decoration: none;}
.appointment-brief .booking-detail{color: #3e3b3b; font-size: 1rem; font-weight: 700; margin-bottom: 0; text-align: right;}
.appointment-brief .booking-schedule{color: #3e3b3b; font-size: 1rem; font-weight: 500; text-align: right; line-height: 1.2; margin-top: 5px;}

/* Optional: Add some additional styling for better aesthetics */
.rounded {
  border-radius: 8px; /* Add rounded corners to the container */
  overflow: hidden; /* Hide overflowing content within the container */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow for depth */
}