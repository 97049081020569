 

 
/* .owl-carousel .owl-item {
    opacity: 0;
    transition: opacity 3.5s ease-in-out;
  }
  
  .owl-carousel .owl-item.active,
  .owl-carousel .owl-item.center {
    opacity: 1;
  }
  
  .fadeOut {
    animation-name: fadeOut;
    animation-duration: 3.5s;
  }
  
  .fadeIn {
    animation-name: fadeIn;
    animation-duration: 3.5s;
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  } */


  /* src/components/FadeCarousel.css */
#fade-carousel.owl-carousel .owl-item {
    opacity: 0;
    transition: opacity 2.5s ease-in-out;
  }
  
  #fade-carousel.owl-carousel .owl-item.active,
  #fade-carousel.owl-carousel .owl-item.center {
    opacity: 1;
  }
  
  .fadeOut {
    animation-name: fadeOut;
    animation-duration: 2.5s;
  }
  
  .fadeIn {
    animation-name: fadeIn;
    animation-duration: 2.5s;
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  