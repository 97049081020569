p.p1 {
    font: 1rem 'Montserrat', sans-serif;
    color: #111111;
}

p.p2 {
    font: 1rem 'Montserrat', sans-serif;
    color: #003367;
}

li.li3 {
    font: 1rem 'Montserrat', sans-serif;
    color: #536272
}

li.li4 {
    font: 1rem 'Helvetica Neue';
    color: #536272
}

span.s1 {
    font: 1rem 'Montserrat', sans-serif;
    background-color: #ffffff
}

span.s2 {
    font: 1rem 'Montserrat', sans-serif
}

span.s3 {}

span.s4 {
    color: #2ea9a5
}

ol.ol1 {
    list-style-type: decimal
}

p.p1 {
    font: 1rem 'Montserrat', sans-serif;
    color: #536272;
}

p.p2 {
    font: 1rem 'Montserrat', sans-serif;
    color: #090511;
}

li.li3 {
    font: 1rem 'Montserrat', sans-serif;
    color: #536272
}

li.li4 {
    margin: 0.0px 0.0px 9.0px 0.0px;
    font: 1rem 'Montserrat', sans-serif;
    color: #536272
}

span.s1 {
    font: 1rem 'Helvetica Neue';
}

span.s2 {
    font: 1rem 'Montserrat', sans-serif;
}

span.s3 {
    background-color: #ffffff
}

span.s4 {
    color: #2ea9a5
}

ol.ol1 {
    list-style-type: decimal
}
